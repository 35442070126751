
import { getRequest } from '@/axios/Api'
import { Delete, Edit, Search, Plus, Message } from '@element-plus/icons-vue'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { IAboutUs } from './interfaces/IEntity'
import { IResp } from './interfaces/IResp'

export default defineComponent({
  setup() {
    const aboutUs = ref<IAboutUs>()
    const mailHref = ref('')

    onMounted(() => {
      getRequest<void>('/AboutUs/newest').then((response) => {
        if (response) {
          console.log(response)
          let resp = response as IResp<IAboutUs>
          aboutUs.value = resp.obj
          mailHref.value = 'mailto:' + resp.obj.email
        }
      })
    })
    return {
      Delete,
      Edit,
      Search,
      Plus,
      Message,
      aboutUs,
      mailHref,
    }
  },
})
