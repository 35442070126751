import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "成功案例",
    meta: {
      title: "成功案例",
    },
    component: HomeView,
  },
  {
    path: "/intellectualproperty",
    name: "知识产权",
    meta: {
      title: "知识产权",
    },
    component: () =>
      import(
        /* webpackChunkName: "intellectualproperty" */ "../views/IntellectualPropertyView.vue"
      ),
  },
  {
    path: "/successfulCase",
    name: "案例详情",
    meta: {
      title: "案例详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "successfulCase" */ "../views/SuccessfulCaseView.vue"
      ),
  },
  {
    path: "/about",
    name: "关于我们",
    meta: {
      title: "关于我们",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
