
import { getRequest } from '@/axios/Api'
import { ISuccessfulCase } from '@/interfaces/IEntity'
import { IResp } from '@/interfaces/IResp'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'HomeView',
  components: {},
  setup() {
    const cases = ref<ISuccessfulCase[]>([])
    onMounted(() => {
      getRequest<void>('/SuccessfulCase/all').then((response) => {
        if (response) {
          let resp = response as IResp<ISuccessfulCase[]>
          cases.value = resp.obj
        }
      })
    })
    return {
      cases,
    }
  },
})
