import axios, { AxiosPromise } from "axios";
import { ElMessage } from "element-plus";
import router from "../router";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "/hyServer/api";
axios.defaults.timeout = 5000;

//配置请求拦截器
axios.interceptors.request.use(
  (req) => {
    //如果缓存中存在token，则在请求头中携带这个token
    const token = sessionStorage.getItem("tokenStr");
    if (token && req.headers) {
      req.headers.Authorization = token;
    }
    return req;
  },
  (error) => {
    console.log(error);
  }
);

//配置响应拦截器
axios.interceptors.response.use(
  (resp) => {
    //成功访问到接口
    if (resp.status && resp.status == 200) {
      //出现业务逻辑错误
      if (
        resp.data.code == 500 ||
        resp.data.code == 401 ||
        resp.data.code == 403
      ) {
        ElMessage.error(resp.data.msg);
        return;
      }
    }
    return resp.data;
  },
  (error) => {
    //未成功访问到接口
    if (error.response.code == 500 || error.response.code == 404) {
      ElMessage.error("无法连接到服务器,请与管理员联系");
    } else if (error.response.code == 403) {
      ElMessage.error("权限不足,请与管理员联系");
    } else if (error.response.code == 401) {
      ElMessage.error("尚未登录,请登录");
      //跳转到登录页
      router.replace("/");
    } else {
      if (error.response.data.msg) {
        ElMessage.error(error.response.data.msg);
      } else {
        ElMessage.error("网络连接错误");
      }
    }
    return;
  }
);

//配置请求

//传送json格式的get请求
export const getRequest = <T>(url: string, params?: T): AxiosPromise => {
  return axios({
    method: "get",
    url: url,
    data: params,
  });
};
//传送json格式的post请求
export const postRequest = <T>(url: string, params?: T): AxiosPromise => {
  return axios({
    method: "post",
    url: url,
    data: params,
  });
};

//传送json格式的put请求
export const putRequest = <T>(url: string, params?: T): AxiosPromise => {
  return axios({
    method: "put",
    url: url,
    data: params,
  });
};

//传送json格式的delete请求
export const deleteRequest = <T>(url: string, params?: T): AxiosPromise => {
  return axios({
    method: "delete",
    url: url,
    data: params,
  });
};
