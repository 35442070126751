import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/axios/Api";

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  next();
});

const app = createApp(App);
app.config.globalProperties.$getRequest = getRequest;
app.config.globalProperties.$postRequest = postRequest;
app.config.globalProperties.$putRequest = putRequest;
app.config.globalProperties.$deleteRequest = deleteRequest;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(ElementPlus, { locale: zhCn }).use(router);
app.mount("#app");
